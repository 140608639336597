import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'gatsby';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import { Grid, AppBar, Toolbar, Avatar, Box } from '@material-ui/core';
import NavbarLinks from './NavbarLinks';
import NavItemMobile from './NavItemMobile';
import logo2 from '../../images/logo_bird.png';
import logo from '../../images/logo_navbar.svg';
import useStyles from './Navbar.styles';
import { useAuth } from '../../hooks/useAuth';
import { navigate } from 'gatsby';

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    open?: boolean;
  }
}

const NavBar = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);
  const { isAuthenticated, getUserFromStorage, logout } = useAuth();
  const user = getUserFromStorage();

  const handleDrawerOpen = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      <div className={classes.row}>
        <AppBar position="fixed" color="default" className={classes.AppBar}>
          <Grid item xs className={classes.container}>
            <Toolbar className={classes.toolbar}>
              <Grid item xs={6} sm={4} md={2} className={classes.growImage}>
                <div className={classes.mainLogo}>
                  <Avatar
                    src={logo}
                    alt="Logo"
                    to="/"
                    component={Link}
                    className={classes.avatar}
                  />
                </div>
              </Grid>
              {
                <Grid item xs={1} sm={4} md={8} className={classes.grow}>
                  <NavbarLinks />
                </Grid>
              }
              {
                <Grid item xs={5} sm={3} md={2} className={classes.grow}>
                  <Button
                    id="ButtonStart"
                    color="inherit"
                    className={classes.registerButton}
                    onClick={(e) => {
                      if (user && !user.completedProfile) {
                        logout();
                      } else if (user && user.completedProfile) {
                        window.location.replace(`${process.env.GATSBY_SITE_URL}/transfer`);
                      } else {
                        navigate(`${process.env.GATSBY_URL_NEW_FRONT}/login`);
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                          event: 'buttonClick',
                          category: 'NavBar',
                          action: 'Click en boton Ingresar',
                          label: 'Barra de navegación, click en Ingresar',
                        });
                      }
                    }}
                  >
                    {user && !user.completedProfile ? 'Cerrar sesión' : 'Ingresar'}
                  </Button>
                </Grid>
              }
              <IconButton
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerOpen}
                className={classes.hide}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </Grid>
          <Grid item xs={12}>
            <Grid className={!open ? classes.hidden : classes.secondarymenu}>
              <NavItemMobile open={open} setOpen={setOpen}></NavItemMobile>
            </Grid>
          </Grid>
        </AppBar>
      </div>
    </>
  );
};

export default NavBar;
