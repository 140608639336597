import React from 'react';
// should add a checkSession api call
import { handleAuthResult, AuthResult, AuthAction } from '../hooks/useAuth';

export type AuthState = {
  user: string | null;
  authResult: AuthResult | null;
  expiresIn: number | null;
  errorType?: string;
  error?: Error;
};

export interface IAuthContext {
  authState: AuthState;
  navigate: any;
  setAuthState: React.Dispatch<AuthAction>;
}

export const AuthContext = React.createContext<IAuthContext>({} as IAuthContext);

export interface IAuthProvider {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigate: any;
  children: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function AuthProvider({ navigate, children }: IAuthProvider) {
  const [authState, setAuthState] = React.useState<AuthState>({
    user: null,
    authResult: null,
    expiresIn: null,
  });
  const [contextValue, setContextValue] = React.useState<IAuthContext>({
    authState,
    navigate,
    setAuthState,
  });

  // Lift the context value into the parent's state to avoid triggering
  // unintentional renders in the consumers
  React.useEffect(() => {
    setContextValue({ ...contextValue, authState });
    console.log('First use effect');
  }, [authState]);

  // Check the session to see if a user is authenticated on mount
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      console.log('Provider: Checking if token is present in localStorage');
      const authResult = localStorage.getItem('bearer');
      const parsedAuthResult = (): AuthResult => (authResult ? JSON.parse(authResult) : null);

      if (authResult) {
        console.log('Provider: Token present');
        const result = handleAuthResult({
          setAuthState,
          authResult: parsedAuthResult(),
        });
        if (!result) navigate('/');
      } else {
        console.log('Provider: Token not present');
      }
    }
  }, []);

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}
