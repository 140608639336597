import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Grid, List, ListItem } from '@material-ui/core';
import { Link } from 'gatsby';

import IconLinkedIn from './../../images/icon_linkedin.svg';
import IconTwitter from './../../images/icon_twitter.svg';
import IconFacebook from './../../images/icon_facebook.svg';
import IconInstagram from './../../images/icon_instagram.svg';
type Props = {
  open: boolean;
  setOpen: (val: boolean) => void;
};
const AppMenu: React.FC<Props> = ({ state, setOpen }) => {
  const classes = useStyles();
  const activeStyles = {
    color: `#FAB234`,
    fontWeight: `bold`,
  };

  function handleClick() {
    setOpen(false);
  }

  return (
    <List component="nav" className={classes.appMenu} disablePadding>
      <ListItem button className={classes.menuItem}>
        <Link onClick={handleClick} activeStyle={activeStyles} to="/" className={classes.link}>
          Personas
        </Link>
      </ListItem>
      <ListItem button className={classes.menuItem}>
        <Link
          onClick={handleClick}
          activeStyle={activeStyles}
          to="/empresas"
          className={classes.link}
        >
          Empresas
        </Link>
      </ListItem>

      <ListItem button className={classes.menuItem}>
        <Link
          onClick={handleClick}
          activeStyle={activeStyles}
          to="/nosotros"
          className={classes.link}
        >
          Nosotros
        </Link>
      </ListItem>

      <ListItem button className={classes.menuItem}>
        <Link
          onClick={handleClick}
          activeStyle={activeStyles}
          to="/compliance"
          className={classes.link}
        >
          Cumplimiento
        </Link>
      </ListItem>
      <ListItem button className={classes.menuItem}>
        <Link
          onClick={handleClick}
          activeStyle={activeStyles}
          to="/beneficios"
          className={classes.link}
        >
          Beneficios
        </Link>
      </ListItem>
      <ListItem button className={classes.menuItem}>
        <Link onClick={handleClick} activeStyle={activeStyles} to="/api" className={classes.link}>
          API
        </Link>
      </ListItem>

      <ListItem button className={classes.menuItem}>
        <a
          className={classes.link}
          id="HelpLink"
          target="_blank"
          href="https://help.currencybird.cl/es/knowledge"
        >
          Ayuda
        </a>
      </ListItem>
      <Grid item xs={12} className={classes.iconGroup}>
        <a
          target="_blank"
          href="https://www.linkedin.com/company/currencybird?originalSubdomain=cl"
        >
          <img src={IconLinkedIn} alt="SocialGroup" />
        </a>
        <a target="_blank" href="https://twitter.com/currency_bird?lang=es">
          <img src={IconTwitter} alt="IconTwitter" />
        </a>
        <a target="_blank" href="https://www.facebook.com/currencybird.cl/">
          <img src={IconFacebook} alt="IconFacebook" />
        </a>
        <a target="_blank" href="https://www.instagram.com/currencybird">
          <img src={IconInstagram} alt="IconInstagram" />
        </a>
      </Grid>
    </List>
  );
};

const drawerWidth = `100%`;

const useStyles = makeStyles((theme) =>
  createStyles({
    appMenu: {
      width: '100%',
      paddingLeft: `6%`,
      paddingRight: `6%`,
      marginBottom: 20,
    },
    navList: {
      width: drawerWidth,
    },
    menuItem: {
      width: drawerWidth,
      justifyContent: `center`,
      borderBottom: `1px solid #3B7892`,
    },
    menuItemIcon: {
      color: '#97c05c',
    },
    link: {
      color: '#fff',
      textDecoration: 'none',
    },
    iconGroup: {
      marginTop: 20,
      marginLeft: 18,
      textAlign: `center`,
      '& img': {
        width: 20,
        marginRight: 20,
      },
    },
  })
);

export default AppMenu;
