import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ...theme.typography.button,
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(1),
      minHeight: '100vh',
      height: `100%`,
    },
    footer: {
      marginTop: 'auto',
      backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    },
    container: {
      [theme.breakpoints.down('md')]: {
        paddingTop: '40px',
      },
      [theme.breakpoints.down(500)]: {
        paddingTop: '20px',
      },
      paddingTop: '20px',
      height: `100%`,
    },
    div: {
      backgroundColor: `#004F71`,
      height: 540,
      paddingTop: 30,
      [theme.breakpoints.down(769)]: {
        height: 'auto',
      },
    },
    h3: {
      margin: 0,
      fontSize: 18,
      textAlign: `left`,
      color: `#fff`,
      fontFamily: `Montserrat`,
      fontWeight: `bold`,
      [theme.breakpoints.down(769)]: {
        textAlign: `center`,
      },
    },
    box: {
      float: `left`,
      width: `20%`,
      height: 200,
      paddingLeft: '3%',
      display: `block`,
      paddingTop: 30,
      textAlign: `left`,
      backgroundColor: `#004F72`,
      [theme.breakpoints.down(769)]: {
        height: 160,
        width: `100%`,
        paddingTop: 0,
      },
      '& li': {
        padding: 0,
      },
    },
    lineSeparator: {
      height: '0.5px',
      backgroundColor: '#fff',
    },
    separator: {
      flex: `0 0 auto`,
      width: `1px`,
      height: `20`,
      margin: `0 10px`,
      background: `currentColor`,
      opacity: `0.1`,
      float: `left`,
    },
    paragraphs: {
      display: `table-cell`,
      verticalAlign: `middle`,
      textAlign: `center`,
      color: `#fff`,
      padding: `1px 5px 0px 5px`,
      [theme.breakpoints.down('md')]: {
        padding: `1px 3px 0px 3px`,
      },
      [theme.breakpoints.down(500)]: {
        fontSize: 17,
        padding: 0,
      },
    },
    group: {
      display: 'flex',
      justifyContent: 'center', // Alinea horizontalmente al centro
      alignItems: 'center',
      width: `100%`,
      paddingLeft: '5%',
      paddingRight: '5%',
      [theme.breakpoints.down(1100)]: {
        paddingLeft: '2%',
        paddingRight: '2%',
      },
      [theme.breakpoints.down(769)]: {
        display: 'block',
        height: 700,
        float: `left`,
        paddingLeft: '20%',
        paddingRight: '20%',
      },
      '& #boxLogoBird': {
        [theme.breakpoints.down(769)]: {
          height: 120,
          textAlign: `center`,
        },
      },
    },
    subGroup: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: `100%`,
      paddingLeft: '10%',
      paddingRight: '10%',
      marginBottom: 20,
      [theme.breakpoints.down(1100)]: {
        paddingLeft: '2%',
        paddingRight: '2%',
      },
      [theme.breakpoints.down(769)]: {
        display: 'block',
        height: 'auto',
        float: `left`,
        paddingLeft: '20%',
        paddingRight: '20%',
      },
      '& #boxLogoBird': {
        [theme.breakpoints.down(769)]: {
          height: 120,
          textAlign: `center`,
        },
      },
    },
    item: {
      [theme.breakpoints.down(769)]: {
        marginBottom: 40,
      },
    },
    transferButton: {
      background: '#FAB234',
      color: '#074F71',
      borderRadius: '40px',
      padding: '0px 25px',
      fontSize: '16px',
      width: `100%`,
      height: 41,
      textTransform: `capitalize`,
      fontFamily: `Montserrat`,
      fontWeight: `bold`,
      '&:hover': {
        background: '#fff',
        color: `#FAB234`,
        border: '2px solid #FAB234',
      },
      [theme.breakpoints.down(1000)]: {
        fontSize: 12,
      },
      [theme.breakpoints.down(769)]: {
        width: 130,
        color: `#074F71`,
        textTransform: `capitalize`,
      },
    },
    subsection: {
      float: `left`,
      paddingLeft: `10%`,
      paddingRight: `10%`,
      width: `100%`,
      height: 100,
      display: `block`,
      backgroundColor: `#fff`,
      [theme.breakpoints.down(1000)]: {
        padding: 0,
      },
      [theme.breakpoints.down(769)]: {
        paddingTop: 50,
      },
      '& #subsectionText': {
        margin: 0,
        width: `80%`,
        float: `left`,
        fontSize: 26,
        textAlign: `center`,
        color: `#074F71`,
        fontFamily: `Montserrat`,
        fontWeight: `normal`,
        paddingTop: 15,
        [theme.breakpoints.down(1281)]: {
          fontSize: 23,
          paddingTop: 8,
        },
        [theme.breakpoints.down(769)]: {
          width: `100%`,
          fontSize: 20,
          fontWeight: `bold`,
          color: `#FAB234`,
        },
      },
      '& #subsectionBox': {
        width: `20%`,
        margin: 0,
        [theme.breakpoints.down(769)]: {
          width: `100%`,
        },
      },
    },
    h4: {
      margin: 0,
      fontSize: 14,
      textAlign: `center`,
      color: `#fff`,
      fontFamily: `Montserrat`,
      fontWeight: `normal`,
      [theme.breakpoints.down(769)]: {
        fontSize: 12,
      },
      [theme.breakpoints.down(500)]: {
        fontSize: 10,
      },
    },
    icon: {
      marginBottom: `20px`,
      [theme.breakpoints.down('md')]: {
        width: 100,
      },
    },
    link: {
      textDecoration: `none`,
      paddingTop: 10,
      display: `inline-block`,
      transition: `all 200ms ease-in`,
      whiteSpace: `nowrap`,
      position: `relative`,
      textAlign: `center`,
      font: `normal normal normal 14px Montserrat`,
      letterSpacing: `0px`,
      color: `#fff`,
      opacity: 1,
      width: `16.6%`,
      '&:hover': {
        color: `#FAB234`,
        fontWeight: `bold`,
      },
      [theme.breakpoints.down(1000)]: {
        fontSize: 10,
      },
      [theme.breakpoints.down(769)]: {
        fontSize: 16,
        display: `block`,
        fontWeight: 300,
        lineHeight: 1,
        width: `100%`,
      },
    },
    iconGroup: {
      marginTop: 10,
      '& img': {
        width: 20,
        marginRight: 20,
        [theme.breakpoints.down(500)]: {
          marginRight: 12,
        },
      },
      [theme.breakpoints.down(769)]: {
        textAlign: `center`,
        marginBottom: 40,
      },
      [theme.breakpoints.down(500)]: {
        textAlign: `center`,
        marginLeft: 12,
      },
      [theme.breakpoints.between(769, 1000)]: {
        '& img': {
          marginRight: 10,
        },
      },
    },
    logo: {
      width: 'auto',
      height: '30px',
    },
    subText: {
      padding: `0 12% 0`,
      marginBottom: 20,
    },
    lineSeparatorContainer: {
      paddingLeft: '12%',
      paddingRight: '12%',
      marginBottom: 30,
    },
    footerBottomSpace: {
      height: '40px',
    },
    copyRight: {
      [theme.breakpoints.down(769)]: {
        height: 150,
      },
    },
  })
);

export default useStyles;
