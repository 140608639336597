import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

// General styles of the theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#074F71',
    },
  },
  typography: {
    fontFamily: "'Montserrat'",
    h1: {
      fontSize: '4.5rem',
    },
    h2: {
      fontSize: '1.375rem',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 501,
      md: 769,
      lg: 1364,
      xl: 1920,
    },
  },
});

export default responsiveFontSizes(theme);
