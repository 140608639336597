import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import HelpIcon from '@material-ui/icons/Help';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../theme';
import { globalHistory } from '@reach/router';

const NavbarLinks = () => {
  const classes = useStyles();

  const activeStyles = {
    color: `#FAB234`,
    fontWeight: `bold`,
  };

  const [referralProgram, setReferralProgram] = useState('not-active');

  useEffect(() => {
    return globalHistory.listen(() => {
      const url = typeof window !== 'undefined' ? window.location.pathname : '';
      if (url == '/beneficios/programa-referidos/') {
        setReferralProgram('active');
      } else {
        setReferralProgram('not-active');
      }
    });
  }, []);

  return (
    <>
      <Link activeStyle={activeStyles} className={classes.link} to="/">
        Personas
      </Link>
      <Link activeStyle={activeStyles} className={classes.link} to="/empresas/">
        Empresas
      </Link>
      <Link activeStyle={activeStyles} className={classes.link} to="/nosotros/">
        Nosotros
      </Link>
      <Link activeStyle={activeStyles} className={classes.link} to="/compliance/">
        Cumplimiento
      </Link>
      <Link
        activeStyle={activeStyles}
        className={classes.link + ' ' + referralProgram}
        to="/beneficios/"
        id="BenefitsLink"
      >
        Beneficios
      </Link>

      <Link activeStyle={activeStyles} id="ApiLink" className={classes.link} to="/api/">
        API
      </Link>
      <a
        className={classes.link}
        id="HelpLink"
        target="_blank"
        href="https://help.currencybird.cl/es/knowledge"
        rel="noreferrer"
      >
        Ayuda
      </a>
    </>
  );
};

const useStyles = makeStyles({
  active: {
    color: `#FAB234`,
    fontWeight: `bold`,
  },
  link: {
    textDecoration: `none`,
    paddingTop: 10,
    display: `inline-block`,
    transition: `all 200ms ease-in`,
    whiteSpace: `nowrap`,
    position: `relative`,
    textAlign: `center`,
    font: `normal normal normal 14px Montserrat`,
    letterSpacing: `0px`,
    color: `#fff`,
    '&.active': {
      color: `#FAB234`,
      fontWeight: `bold`,
    },
    opacity: 1,
    width: `14.2%`,
    //paddingLeft: 20,
    '&:hover': {
      color: `#FAB234`,
      fontWeight: `bold`,
    },
    [theme.breakpoints.down(1280)]: {
      fontSize: 11,
    },
    [theme.breakpoints.down(769)]: {
      padding: `20px 0`,
      fontSize: `10px`,
      display: `none`,
    },
  },
});

export default NavbarLinks;
