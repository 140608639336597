import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import theme from '../../theme';
import LogoBird from '../../images/logo_bird.svg';

const drawerWidth = 240;

const useStyles = makeStyles({
  row: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    height: 72,
    paddingTop: 13,
    [theme.breakpoints.down(500)]: {
      paddingTop: 18,
    },
    '& #HelpLink': {
      [theme.breakpoints.up(768)]: {
        paddingRight: 50,
      },
    },
    '& #BenefitsLink': {
      [theme.breakpoints.up(768)]: {
        right: '-2%',
      },
    },
    '& #ApiLink': {
      [theme.breakpoints.up(768)]: {
        right: '0%',
      },
    },
  },
  growImage: {
    flexGrow: 1,
    height: 72,
    [theme.breakpoints.down(769)]: {
      width: '30%',
    },
  },
  container: {
    width: 1365,
    margin: 'auto',
    padding: `0 6% 0`,
    [theme.breakpoints.up(1921)]: {
      padding: 0,
    },
  },
  toolbar: {
    height: 72,
    paddingLeft: 0,
    paddingRight: 0,
  },
  AppBar: {
    height: 72,
    backgroundColor: '#074F71',
    backgroundSize: 'cover',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  mainLogo: {
    color: '#a1a1a1',
    paddingTop: 20,
    justifyContent: 'left',
    '&:hover': {
      background: 'transparent',
    },
    [theme.breakpoints.down(769)]: {
      width: `80%`,
    },
    [theme.breakpoints.down(501)]: {
      paddingTop: 17,
      width: `100%`,
    },
    [theme.breakpoints.down(425)]: {
      paddingTop: 22,
    },
    [theme.breakpoints.down(375)]: {
      paddingTop: 25,
    },
  },
  avatar: {
    height: '100%',
    width: '95%',
    borderRadius: 0,
    // [theme.breakpoints.down(501)]: {
    //   content: `url('${LogoBird}')`,
    // },
  },
  hide: {
    color: '#fff',
    [theme.breakpoints.up(768)]: {
      display: 'none',
    },
    [theme.breakpoints.down(769)]: {
      display: 'flex',
    },
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  registerButton: {
    background: '#F9B234',
    color: '#074F71',
    borderRadius: '34px',
    padding: '0px 10px',
    fontSize: '16px',
    width: '90%',
    marginLeft: '0%',
    marginRight: '10%',
    height: 41,
    textTransform: `capitalize`,
    fontWeight: `bold`,
    '&:hover': {
      background: '#074F71',
      color: `#FAB234`,
      border: '1px solid #FAB234',
    },
    [theme.breakpoints.down(1000)]: {
      fontSize: 12,
    },
    [theme.breakpoints.down(500)]: {
      height: 35,
      width: `90%`,
    },
    // [theme.breakpoints.down(769)]: {
    //   background:'#004F71',
    //   border: '1px solid #F8B133',
    //   color:`#F8B133`,

    // },
  },
  loginText: {
    flexGrow: 1,
    height: 72,
    paddingTop: 18,
    '& a': {
      fontSize: 14,
      fontFamily: `Montserrat`,
      textDecoration: `none`,
      color: `#fff`,
      fontWeight: `bold`,
      [theme.breakpoints.down(900)]: {
        fontSize: 12,
      },
      [theme.breakpoints.down(600)]: {
        fontSize: 10,
      },
      [theme.breakpoints.down(501)]: {
        display: `none`,
      },
      '&:hover': {
        color: `#FAB234`,
      },
    },
  },
  secondarymenu: {
    position: `absolute`,
    left: 0,
    right: 0,
    display: `flex`,
    height: `auto`,
    fontSize: `14px`,
    width: `100%`,
    flexWrap: `nowrap`,
    alignItems: `center`,
    justifyContent: `space-between`,
    padding: `0 0.55rem`,
    transition: `height 0.3s`,
    userSelect: `none`,
    overflowX: `scroll`,
    overflowY: `hidden`,
    background: `#074F71`,
    [theme.breakpoints.up(769)]: {
      display: `none`,
    },
    [theme.breakpoints.down(769)]: {
      padding: 0,
    },
  },
  hidden: {
    display: `none`,
  },
});

export default useStyles;
