import React from 'react';
import { Link } from 'gatsby';
import Container from '@material-ui/core/Container';
import { Grid, Avatar, ListItem, Hidden } from '@material-ui/core';
import useStyles from './Footer.styles';
import Copyright from './Copyright';
import LogoBird from './../../images/cb_footer_icon.png';
import IconLinkedIn from './../../images/icon_linkedin.svg';
import IconTwitter from './../../images/icon_twitter.svg';
import IconFacebook from './../../images/icon_facebook.svg';
import IconInstagram from './../../images/icon_instagram.svg';
import IconYoutube from './../../images/youtube_white.svg';
import FintecLogo from './../../images/fintelogo.png';
import CCIcon from './../../images/ccbclogo.png';
import AppStore from './../../images/appstorewhite.png';
import Gplay from './../../images/gplaywhite.png';

export default function StickyFooter() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <React.Fragment>
        {
          <Grid item xs={12} sm={12} md={12} className={classes.div}>
            <Container className={classes.container} maxWidth="lg">
              <Grid item xs={12} className={classes.group}>
                <Hidden mdUp>
                  <Grid id="boxLogoBird" className={classes.box}>
                    <img className={classes.icon} src={LogoBird} alt="LogoBird" />
                  </Grid>
                </Hidden>
                <Grid className={classes.box}>
                  <h3 className={classes.h3}>Empresa y equipo</h3>
                  <ListItem>
                    <Link className={classes.link} to="/nosotros">
                      Nosotros
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link className={classes.link} to="/">
                      Personas
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link className={classes.link} to="/empresas">
                      Empresas
                    </Link>
                  </ListItem>
                </Grid>
                {/* <Grid className={classes.separator} /> */}
                <Grid className={classes.box}>
                  <h3 className={classes.h3}>Ayuda</h3>
                  <ListItem>
                    <a
                      className={classes.link}
                      target="_blank"
                      href="https://help.currencybird.cl/es/knowledge"
                    >
                      Centro de ayuda
                    </a>
                  </ListItem>
                  <ListItem>
                    <a
                      className={classes.link}
                      target="_blank"
                      href="https://blog.currencybird.cl/"
                    >
                      Blog y novedades
                    </a>
                  </ListItem>
                </Grid>
                {/* <NavSeparator /> */}
                <Grid className={classes.box}>
                  <h3 className={classes.h3}>Legal</h3>
                  <ListItem>
                    <Link className={classes.link} to="/terms/">
                      Términos y condiciones
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link className={classes.link} to="/privacypolicy/">
                      Política de privacidad
                    </Link>
                  </ListItem>
                  <ListItem>
                    <a
                      className={classes.link}
                      target="_blank"
                      href="https://www.uaf.cl/legislacion/nuestra_ley.aspx"
                    >
                      Ley 19.913
                    </a>
                  </ListItem>
                  <ListItem>
                    <a
                      className={classes.link}
                      target="_blank"
                      href="https://www.bcn.cl/leychile/navegar?idNorma=1008668"
                    >
                      Ley 20.393
                    </a>
                  </ListItem>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={12} className={classes.subGroup}>
                <Grid container justifyContent="space-evenly" alignItems="center">
                  <Hidden smDown>
                    <Grid item className={classes.item}>
                      <img
                        className={classes.icon}
                        src={LogoBird}
                        alt="LogoBird"
                        style={{ height: '50px' }}
                      />
                    </Grid>
                  </Hidden>
                  <Grid item className={classes.item}>
                    <a target="_blank" href="https://www.fintechile.org/">
                      <img src={FintecLogo} alt="Fintec Logo" className={classes.logo} />
                    </a>
                  </Grid>
                  <Grid item className={classes.item}>
                    <a target="_blank" href="https://camarachilenobrasilena.cl/">
                      <img
                        src={CCIcon}
                        alt="CCBC Logo"
                        className={classes.logo}
                        style={{ height: '70px' }}
                      />
                    </a>
                  </Grid>
                  <Grid item className={classes.iconGroup}>
                    <a target="_blank" href="https://www.facebook.com/currencybird.cl/">
                      <img src={IconFacebook} alt="IconFacebook" />
                    </a>
                    <a target="_blank" href="https://twitter.com/currency_bird?lang=es">
                      <img src={IconTwitter} alt="IconTwitter" />
                    </a>
                    <a target="_blank" href="https://www.youtube.com/@currencybird/videos">
                      <img src={IconYoutube} alt="IconYoutube" />
                    </a>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/currencybird?originalSubdomain=cl"
                    >
                      <img src={IconLinkedIn} alt="SocialGroup" />
                    </a>
                    <a target="_blank" href="https://www.instagram.com/currencybird">
                      <img src={IconInstagram} alt="IconInstagram" />
                    </a>
                  </Grid>
                  <Grid item style={{ paddingTop: 10 }} className={classes.item}>
                    <a
                      target="_blank"
                      href="https://apps.apple.com/us/app/currencybird-env%C3%ADos-de-dinero/id6503993314"
                    >
                      <img src={AppStore} alt="Appstore Logo" className={classes.logo} />
                    </a>
                  </Grid>
                  <Grid item style={{ paddingTop: 10 }} className={classes.item}>
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=cl.currencybird.cbmobileapp"
                    >
                      <img src={Gplay} alt="Gplay Logo" className={classes.logo} />
                    </a>
                  </Grid>
                </Grid>
              </Grid>

              <Hidden smDown>
                <Grid className={classes.lineSeparatorContainer}>
                  <div className={classes.lineSeparator}></div>
                </Grid>
              </Hidden>
              <Grid item className={classes.subText} xs={12}>
                <h4 className={classes.h4}>
                  CurrencyBird SpA es una empresa registrada ante la Unidad de Análisis Financiero
                  UAF y regida bajo la Ley Orgánica del Banco Central de Chile.
                </h4>
              </Grid>
              <Grid item xs={12} className={classes.copyRight}>
                <Copyright />
              </Grid>
            </Container>
          </Grid>
        }
      </React.Fragment>
    </footer>
  );
}
