import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../theme';

function Copyright() {
  const classes = useStyles();
  const year = new Date().getFullYear();
  return (
    <Typography
      className={classes.Copyright}
      variant="body2"
      style={{ color: '#fff' }}
      align="center"
    >
      <Link color="inherit" href="/">
        <b>©CURRENCYBIRD {year} </b>, Ramón Ricardo Rosas 663, Puerto Varas, Chile
      </Link>
    </Typography>
  );
}
const useStyles = makeStyles({
  Copyright: {
    '& p': {
      fontWeight: `normal`,
    },
    [theme.breakpoints.down(769)]: {
      fontSize: `15px`,
      padding: `0 20% 0`,
    },
  },
});

export default Copyright;
